.token {
  border: 1.5px solid #000000;
  background-color: rgb(211, 211, 211);
  height: 100%;
  width: 300px;
  box-shadow: 12.5px 16px #a9a9a9;
  margin: 10px;
}

.token span {
  text-align: center;
}

.token p {
  color: #000000;
}
.token p span {
  color: #000080;
}
